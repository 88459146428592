import React, { useState, useCallback } from "react";
import { JSX } from "react/jsx-runtime";
import { Input } from '@nextui-org/react';

export const SearchIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            aria-hidden='true'
            fill='none'
            focusable='false'
            height='1em'
            role='presentation'
            viewBox='0 0 24 24'
            width='1em'
            {...props}
        >
            <path
                d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
            />
            <path
                d='M22 22L20 20'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
            />
        </svg>
    );
};

export const CloseFilledIcon = (props: any) => {
    return (
        <svg
            aria-hidden='true'
            focusable='false'
            height='1em'
            role='presentation'
            viewBox='0 0 24 24'
            width='1em'
            {...props}
        >
            <path
                d='M12 2a10 10 0 1010 10A10.016 10.016 0 0012 2zm3.36 12.3a.754.754 0 010 1.06.748.748 0 01-1.06 0l-2.3-2.3-2.3 2.3a.748.748 0 01-1.06 0 .754.754 0 010-1.06l2.3-2.3-2.3-2.3A.75.75 0 019.7 8.64l2.3 2.3 2.3-2.3a.75.75 0 011.06 1.06l-2.3 2.3z'
                fill='currentColor'
            />
        </svg>
    );
};

// Define the type for the debounce function
type DebounceFunction = (func: (...args: any[]) => void, delay: number) => (...args: any[]) => void;

interface MyInputProps {
    onChangeValue: (value: string) => void
    shouldDisable: boolean
    searchDescription: string
}

export default function MyInput({ onChangeValue, shouldDisable, searchDescription }: MyInputProps) {
    // State to hold the input value and debounced value
    const [inputValue, setInputValue] = useState<string>(""); // Immediate input value
    // eslint-disable-next-line
    const [debouncedValue, setDebouncedValue] = useState<string>(""); // Debounced input value

    // Debounce utility function
    const debounce: DebounceFunction = (func, delay) => {
        let timer: NodeJS.Timeout | null = null;
        return (...args: any[]) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };
    // Handler for input changes
    const handleInputChange = useCallback(
        (value: string) => {
            setInputValue(value); // Update the input value immediately
            debouncedUpdate(value); // Call the debounced function
        },
        []
    );

    // Debounced update function
    const debouncedUpdate = useCallback(
        debounce((value: string) => {
            setDebouncedValue(value);
            if (onChangeValue) {
                onChangeValue(value)
            }
        }, 1000),
        []
    );

    return (
        <>
            <div className='bg-gradient-to-r from-darkBlue/50 to-lightOrange/50 text-white font-semibold p-1 w-full mb-5 rounded-lg'>
                <Input
                    isClearable
                    disabled={shouldDisable}
                    className='flex w-full bg-lightGrey text-white text-2xl '
                    classNames={{
                        label: "!text-darkBlue dark:text-white/90 font-bold text-medium",
                        input: [
                            "bg-transparent",
                            "text-black/90 dark:text-white/90",
                            "text-bold",
                            "placeholder:text-default-700/80 dark:placeholder:text-white/60",
                        ],
                        innerWrapper: "bg-transparent",
                        inputWrapper: [
                            // "shadow-xl",
                            "bg-default-200/50",
                            "dark:bg-default/60",
                            "backdrop-blur-xl",
                            "backdrop-saturate-200",
                            "hover:bg-default-200/70",
                            "rounded-sm",
                            "dark:hover:bg-default/70",
                            "group-data-[focus=true]:bg-default-200/50",
                            "dark:group-data-[focus=true]:bg-default/60",
                            "!cursor-text",
                        ],
                        clearButton: [
                            "bg-lightOrange/30"
                        ]
                    }}
                    label='Search'
                    placeholder={searchDescription}
                    radius='lg'
                    value={inputValue} // Bind the input value to the state
                    onChange={(e) => handleInputChange(e.target.value)} // Handle input changes
                    startContent={
                        <SearchIcon className='text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0'/>
                    }
                    onClear={() => {
                        setInputValue('')
                        onChangeValue('')
                    }}
                />
            </div>
        </>


    );
}

