import { useEffect, useState } from "react";
import axios from 'axios';

const useFetchDocuments = (collectionName = 'courses') => {
    const [documents, setDocuments] = useState(null);
    const [loadingAllDocs, setLoadingAllDocs] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                // Check local storage for existing data
                const storedData = localStorage.getItem("coursesData");
                const showCourses = localStorage.getItem("showCourses");
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    const { timestamp, data } = parsedData;

                    // Check if the data is older than 30 days
                    const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
                    const currentTime = new Date().getTime();
                    if (currentTime - timestamp < thirtyDaysInMs) {
                        // Use the cached data
                        setDocuments(data);
                        setLoadingAllDocs(false);
                        return;
                    }
                }


                // Define the Firebase Cloud Function URL
                const cloudFunctionUrl = `https://recursivegetalldocumentsandsubcollectionscloudusj-w2n23axivq-uc.a.run.app/recursiveGetAllDocumentsAndSubcollectionsCloudUsingOnRequest`;
                if (showCourses) {
                    // Make a GET request to the Cloud Function with the collectionName as a query parameter
                    const response = await axios.get(cloudFunctionUrl, {
                        params: { collectionName }, // Pass collectionName as a query parameter
                    });

                    // Extract and set the data from the response
                    setDocuments(response.data.data);
                    const newDataToStore = {
                        timestamp: new Date().getTime(),
                        data: response?.data?.data,
                    };
                    localStorage.setItem("coursesData", JSON.stringify(newDataToStore));
                } else {
                    // @ts-ignore
                    setDocuments([]);
                }
                setLoadingAllDocs(false);

            } catch (err) {
                console.error("Error calling Cloud Function:", err);
                setError(true);
                setLoadingAllDocs(false);
            }
        };

        fetchDocuments();
    }, []); // Empty dependency array ensures this runs only once on mount

    return { documents, loadingAllDocs, error };
};

// const useFetchDocuments = (collectionName='courses') => {
//     const [data, setData] = useState(null); // To store the fetched data
//     const [loading, setLoading] = useState(true); // To track loading state
//     const [error, setError] = useState(null); // To store any errors
//
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 // Define the Firebase Cloud Function URL
//                 const cloudFunctionUrl = `https://<region>-<project-id>.cloudfunctions.net/recursiveGetAllDocumentsAndSubcollectionsCloudUsingOnCall`;
//
//                 // Make a GET request to the Cloud Function with the collectionName as a query parameter
//                 const response = await axios.get(cloudFunctionUrl, {
//                     params: { collectionName }, // Pass collectionName as a query parameter
//                 });
//
//                 // Extract and set the data from the response
//                 setData(response.data.data);
//                 setLoading(false);
//             } catch (err) {
//                 console.error('Error fetching documents:', err);
//                 setError(err.message || 'An error occurred while fetching documents');
//                 setLoading(false);
//             }
//         };
//
//         // Call the fetchData function if collectionName is provided
//         if (collectionName) {
//             fetchData();
//         } else {
//             setError('Missing collectionName parameter');
//             setLoading(false);
//         }
//     }, [collectionName]); // Re-run the effect if collectionName changes
//
//     return { data, loading, error };
// };

export default useFetchDocuments;