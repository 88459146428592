import { Select, SelectItem } from "@nextui-org/react";

export const animals = [
    { key: "nishkamaKarmaYoga", label: "Nishkama Karma Yoga" },
    { key: "suddhaBhakti", label: "Suddha Bhakti" },
    { key: "varnasrama", label: "Varnasrama" },
    { key: "parenting", label: "Parenting" },
];

interface SelectProps {
    onChangeValue: (value: string) => void
    shouldDisable: boolean
}

export default function CustomSelectComponent({ onChangeValue, shouldDisable }: SelectProps) {

    return (
        <div className='bg-gradient-to-r from-lightOrange/50 to-darkBlue/50 text-white font-semibold p-1 w-full mb-5 rounded-lg'>
            <Select
                className='flex w-full bg-lightGrey text-white'
                label='Select from tags'
                isDisabled={shouldDisable}
                radius={'sm'}
                onChange={(e) => {
                    onChangeValue(e.target.value)
                }}
                placeholder='Click to Select'
                classNames={{
                    label: "!text-darkBlue dark:text-white/90 font-bold text-medium",
                    innerWrapper: "bg-transparent rounded-sm",
                    base: "rounded-md",
                    trigger: "bg-lightGrey/10"
                }}

            >
                {animals.map((animal) => (
                    <SelectItem key={animal.key}>{animal.label}</SelectItem>
                ))}
            </Select>
        </div>
    );
}
