import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Input, Select, SelectItem, Modal, ModalFooter, Textarea, Button, Spacer, ModalHeader, ModalContent } from "@nextui-org/react";
import { useTheme } from 'next-themes';
import { getFunctions, httpsCallable } from "firebase/functions";
import { motion } from 'framer-motion';

export const topics = [
    { label: "Are you Sure, Your Bhakti is pure?", value: "Are you Sure, Your Bhakti is pure?" },
    { label: "Śrī Bhaktyāloka", value: "Śrī Bhaktyāloka" },
    { label: "Śrīmad-Bhāgavatam classes given at ISKCON Chowpatty", value: "Śrīmad-Bhāgavatam classes given at ISKCON Chowpatty" },
    { label: "Śrī Hari-Nāma-Cintāmaṇi", value: "Śrī Hari-Nāma-Cintāmaṇi" },
    { label: "Śrī Kṛṣṇa Smaraṇa Sphūrti- Vaiṣṇava Songs", value: "Śrī Kṛṣṇa Smaraṇa Sphūrti- Vaiṣṇava Songs" },
    { label: "Śrī Upadeśāmṛta", value: "Śrī Upadeśāmṛta" },
    { label: "Śuddha Bhakti Cintāmaṇi", value: "Śuddha Bhakti Cintāmaṇi" },
    { label: "Bhagavad-gītā", value: "Bhagavad-gītā" },
    { label: "Brahma-saṁhitā (with Śrīla Jīva Gosvāmī's Commentary)", value: "Brahma-saṁhitā (with Śrīla Jīva Gosvāmī's Commentary)" },
    { label: "Studying Bhāgavatam together with Reflection and Relish", value: "Studying Bhāgavatam together with Reflection and Relish" },
    { label: "Bhakti Tattva Viveka", value: "Bhakti Tattva Viveka" },
    { label: "Śrī Śikṣāṣṭakam", value: "Śrī Śikṣāṣṭakam" },
    { label: "Bhakti Sanga Classes", value: "Bhakti Sanga Classes" },
    { label: "Jaiva Dharma", value: "Jaiva Dharma" },
    { label: "Śrī Caitanya Śikṣāmṛta", value: "Śrī Caitanya Śikṣāmṛta" },
    { label: "Śrī Manaḥ-Śikṣā", value: "Śrī Manaḥ-Śikṣā" },
    { label: "Śrīmad-Bhāgavatam", value: "Śrīmad-Bhāgavatam" },
    { label: "Bhakti Rasāmrta Sindhu", value: "Bhakti Rasāmrta Sindhu" },
    { label: "Mādhurya Kādambinī", value: "Mādhurya Kādambinī" },
    { label: "Qualification to hear Krsna's Pastimes", value: "Qualification to hear Krsna's Pastimes" },
    { label: "Śrī Bṛhad-bhāgavatāmṛta", value: "Śrī Bṛhad-bhāgavatāmṛta" },
    { label: "Laghu Bhāgavatamrta", value: "Laghu Bhāgavatamrta" },
    { label: "Sad Sandharbas", value: "Sad Sandharbas" },
]

const ContactMe = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 250)

    }, [])
    const initialState = {
        question: {
            value: '',
            isValid: true,
        },
        name: {
            value: '',
            isValid: true,
        },
        email: {
            value: '',
            isValid: true,
        },
    };
    const { theme } = useTheme();
    const [inputs, setInputs] = useState(initialState);

    const [selectedTopic, setSelectedTopic] = React.useState(new Set([]));
    const [submitting, setSubmitting] = React.useState(false);
    const [messageSent, setMessageSent] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [topicError, setTopicError] = React.useState(false);

    useEffect(() => {
        if (!!selectedTopic.keys().next().value) {
            setTopicError(false)
        }
    }, [selectedTopic]);

    async function submitHandler() {
        let formData = {
            question: inputs.question.value,
            name: inputs.name.value,
            email: inputs.email.value,
            topic: selectedTopic.keys().next().value
        };
        const nameIsValid = formData.name.trim().length > 0;
        const emailIsValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email.trim());
        const questionIsValid = formData.question.trim().length > 0;
        const topicIsValid = !!selectedTopic.keys().next().value

        if (!topicIsValid) {
            setTopicError(true)
        }

        if ((!nameIsValid || !emailIsValid || !questionIsValid)) {
            setInputs((curInputs) => {
                return {
                    ...curInputs,
                    question: {
                        value: curInputs.question.value,
                        isValid: questionIsValid,
                    },
                    name: {
                        value: curInputs.name.value,
                        isValid: nameIsValid,
                    },
                    email: {
                        value: curInputs.email.value,
                        isValid: emailIsValid,
                    },
                };
            });
            return;
        }
        setSubmitting(true)
        const functions = getFunctions();
        const sendMailToMyself = httpsCallable(functions, 'sendMail');
        try {
            const result = await sendMailToMyself(formData)
            if (result) {
                setInputs(initialState);
                setSubmitting(false)
                setMessageSent(true)
            }
        } catch (err) {
            setError(true)
            setSubmitting(false)
            setInputs(initialState);
        }

    }

    function inputChangedHandler(inputIdentifier: string, enteredValue: any) {
        setInputs((curInputs) => {
            return {
                ...curInputs,
                [inputIdentifier]: { value: enteredValue, isValid: true },
            };
        });
    }

// Click to choose course
    return (
        <div className={'grid-rows-schedule-row grid-cols-schedule-column grid xs:h-full sm:h-screen sm:grid-cols-8 sm:mt-0 mt-12'}>
            <div className={'col-span-full bg-contact brightness-50 bg-fixed bg-center xs:h-full bg-cover bg-no-repeat  sm:block hidden opacity-90 row-span-full'}/>
            <motion.div className={'col-start-2 col-end-8  lg:col-start-3 lg:col-end-7 opacity-[97%] row-start-2 row-span-3 h-screen'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 0.5,
                            ease: "easeOut",
                        }}
            >
                <Card className={'grid grid-cols-8'}>
                    <CardHeader className='pb-0 pt-2 px-4 flex-col items-center col-span-full'>
                        <Spacer y={2}/>
                        <h1 className='font-bold text-3xl text-red dark:text-lightGrey mt-2'>Questions?</h1>
                    </CardHeader>
                    <CardBody className='overflow-visible py-2 md:col-start-2 md:col-end-8 col-start-1 col-end-9 '>
                        {/*<Spacer y={4}/>*/}
                        <p className={'mb-10 text-center'}>Ask your course related questions below</p>
                        <Input type='text' label='Name' size={'lg'} variant={'bordered'} radius={'lg'} className={'mb-7'}
                               isInvalid={!inputs.name.isValid}
                               isRequired
                               value={inputs.name.value}
                               onValueChange={text =>
                                   inputChangedHandler('name', text)
                               }
                        />
                        <Input type='email' label='Email' variant={'bordered'} radius={'lg'} size={'lg'} className={'mb-7'} isRequired
                               isInvalid={!inputs.email.isValid}
                               value={inputs.email.value}
                               errorMessage={!inputs.email.isValid && "Enter a valid email"}
                               onValueChange={text =>
                                   inputChangedHandler('email', text)
                               }
                        />
                        <Select
                            label='Topic'
                            isInvalid={topicError}
                            placeholder='Click to choose a topic'
                            variant={'bordered'}
                            errorMessage={topicError && "CustomSelectComponent a topic"}
                            isRequired
                            size={'lg'}
                            radius={'lg'}
                            className={'mb-7'}
                            selectedKeys={selectedTopic}
                            //@ts-ignore
                            onSelectionChange={setSelectedTopic}
                        >
                            {topics.map((topic) => (
                                <SelectItem key={topic.value} value={topic.value}>
                                    {topic.label}
                                </SelectItem>
                            ))}
                        </Select>

                        <Textarea
                            isRequired
                            label='Question'
                            variant='bordered'
                            isInvalid={!inputs.question.isValid}
                            placeholder='Type your question'
                            disableAnimation
                            disableAutosize
                            classNames={{
                                base: "mb-7",
                                input: "resize-y min-h-[120px]",
                            }}
                            value={inputs.question.value}
                            onValueChange={text =>
                                inputChangedHandler('question', text)
                            }
                        />
                    </CardBody>
                    {/*<div className={'md:col-start-4 md:col-end-7 justify-self-center h-10'}>*/}
                    {/*    {!messageSent &&*/}
                    {/*        <span className={'text-red font-bold'}>Message sent</span>*/}
                    {/*    }*/}
                    {/*    {error &&*/}
                    {/*        <span className={'text-red font-bold'}> Error in sending message - Try again later*/}
                    {/*        </span>}*/}
                    {/*</div>*/}

                    <Button color='primary' className={'col-start-4 col-end-6 lg:col-start-4 lg:col-end-6 mb-7 justify-self-center bg-darkBlue dark:bg-lightGrey'} size={'lg'} radius={'lg'} variant={`${theme === 'light' ? `solid` : 'bordered'}`} onClick={submitHandler} disabled={submitting}>
                        {submitting ? 'Sending...' : 'Send Message'}
                    </Button>
                    {messageSent &&
                        <Modal isOpen={messageSent} onOpenChange={() => setMessageSent(false)} placement={'bottom-center'}
                               classNames={{
                                   header: "border-b-[1px] border-[#d9d9d9]",
                               }}
                        >
                            <ModalContent>
                                {(onClose) => (
                                    <>
                                        <ModalHeader className='flex flex-col gap-1 text-center'>
                                            Message sent
                                        </ModalHeader>
                                        <ModalFooter className={'justify-center'}>
                                            <Button color='primary' onPress={onClose} className={'bg-darkBlue dark:bg-lightGrey'} variant={`${theme === 'light' ? `solid` : 'bordered'}`}>
                                                Close
                                            </Button>
                                        </ModalFooter>
                                    </>
                                )}
                            </ModalContent>
                        </Modal>
                    }
                    {error &&
                        <Modal isOpen={error} onOpenChange={() => setError(false)} placement={'bottom-center'}
                               classNames={{
                                   header: "border-b-[1px] border-[#d9d9d9]",
                               }}
                        >
                            <ModalContent>
                                {(onClose) => (
                                    <>
                                        <ModalHeader className='flex flex-col gap-1 text-center'>
                                            Error in sending message - Try again later
                                        </ModalHeader>
                                        <ModalFooter className={'justify-center'}>
                                            <Button color='primary' onPress={onClose} className={'bg-darkBlue dark:bg-lightGrey'} variant={`${theme === 'light' ? `solid` : 'bordered'}`}>
                                                Close
                                            </Button>
                                        </ModalFooter>
                                    </>
                                )}
                            </ModalContent>
                        </Modal>
                    }
                </Card>
            </motion.div>
        </div>
    );
};

export default ContactMe;
